(function() {
  var Forms, Home, Menu, Pages, checkPage, paceOptions, strip;

  window.App || (window.App = {});

  App.init = function(e) {
    if (checkPage('home')) {
      App.Home.init();
    }
  };

  $(document).ready((function(_this) {
    return function() {
      App.init();
    };
  })(this));

  App.Home = {
    init: function(e) {
      Home.createSlider();
    },
    createSlider: function(e) {
      var homeSlider;
      homeSlider = document.querySelector('.hero-slider');
      App.Home.flkty = new Flickity(homeSlider, {
        cellAlign: 'left',
        autoPlay: 15000,
        pauseAutoPlayOnHover: false,
        contain: true,
        wrapAround: true,
        draggable: false,
        prevNextButtons: false,
        pageDots: false
      });
    },
    playVideo: function(target, url, player) {
      var container;
      container = $(target).parent();
      target.remove();
      container.addClass('active');
      window.setTimeout((function() {
        player.attr('src', url);
        player.addClass('visible');
      }), 250);
    }
  };

  Home = App.Home;

  $(document).on('click', '.home .slider-controls .play-controller', function(e) {
    var el, icon;
    el = $(this);
    icon = $(this).find('.icon');
    el.toggleClass('play');
    if (!el.hasClass('play')) {
      icon.addClass('fa-pause').removeClass('fa-play');
      App.Home.flkty.pausePlayer();
    } else {
      icon.removeClass('fa-pause').addClass('fa-play');
      App.Home.flkty.unpausePlayer();
    }
  });

  $(document).on('click', '.home .slider-controls .next', function(e) {
    var el;
    el = $('.slider-controls .play-controller');
    App.Home.flkty.next();
  });

  $(document).on('click', '.home .slider-controls .prev', function(e) {
    App.Home.flkty.previous();
  });

  $('.start-video').on('click', function(e) {
    var frame, target, url;
    target = $(e.target);
    url = target.data('embed');
    frame = target.parent().find('iframe');
    Home.playVideo(target, url, frame);
  });

  $('#mobileMenuTrigger').on('click', function(e) {
    var icon, menu;
    icon = $(this);
    menu = $('#mobileMenu ul');
    icon.toggleClass('open');
    if (icon.hasClass('open')) {
      icon.find('.fa-bars').removeClass('visible');
      icon.find('.fa-close').addClass('visible');
    } else if (!icon.hasClass('open')) {
      icon.find('.fa-bars').addClass('visible');
      icon.find('.fa-close').removeClass('visible');
    }
    $('#mobileMenu').toggleClass('open');
  });

  $('.dropdown-menu-toggle').on('click', function(e) {
    var menu;
    menu = $(this).toggleClass('open');
  });

  App.Menu = {
    init: (function(_this) {
      return function(e) {
        console.log('Menu init');
      };
    })(this),
    updateDropdown: function(item, show) {
      var dropdown, header, subMenu;
      item = $(item);
      header = $('.site-header');
      subMenu = header.find('.site-submenu');
      if (show) {
        subMenu.addClass('visible');
      } else {
        subMenu.removeClass('visible');
      }
      dropdown = subMenu.find('#' + item.data('content'));
      if (!dropdown.hasClass('active')) {
        $('.site-submenu .dropdown').removeClass('active');
        setTimeout((function() {
          dropdown.addClass('active');
        }), 50);
      }
      subMenu.css({
        'height': dropdown.innerHeight()
      });
    }
  };

  Menu = App.Menu;

  $('.site-menu .dropdown-toggle').on('mouseenter', function(e) {
    Menu.updateDropdown(e.target, true);
  });

  $('.site-menu .dropdown-toggle').on('mouseleave', function(e) {
    Menu.updateDropdown(e.target, true);
  });

  $('.site-header .menu-wrapper').on('mouseleave', function(e) {
    $('.site-submenu').removeClass('visible');
    $('.site-submenu .dropdown').removeClass('active');
    $('.site-submenu').css({
      'height': 0
    });
  });

  App.Pages = {
    init: (function(_this) {
      return function(e) {};
    })(this)
  };

  Pages = App.Pages;

  $(document).on('change', '#textSize', function(e) {
    var scale;
    scale = $(this).val();
    $('#content.content-wrapper').attr('class', 'content-wrapper scale-' + scale);
  });

  App.Forms = {
    formatPhone: function(field) {
      var x;
      x = field.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return field.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
  };

  Forms = App.Forms;

  $(document).on('input', 'input.phone-mask, .ginput_container_phone input', function(e) {
    return Forms.formatPhone(e.target);
  });

  checkPage = function(className) {
    var classList;
    classList = $('body').attr('class').split(' ');
    return classList.indexOf(className) > -1;
  };

  strip = function(html) {
    var tmp;
    tmp = document.implementation.createHTMLDocument('New').body;
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  paceOptions = {
    ajax: true,
    document: true,
    eventLag: false,
    restartOnPushState: true,
    minTime: 0,
    catchupTime: 0,
    maxProgressPerFrame: 100,
    ghostTime: 25,
    restartOnRequestAfter: 0,
    easeFactor: .5
  };

}).call(this);
